const fxaddUsers = async (
    accessLevel,
    districtOfficeID,
    regionalOfficeID,
    regionalOffice,
    districtOffice,
    positionName,
    employeeNo,
    firstName,
    middleName,
    lastName,
    password,
    mobileNo,
    email,
    status,
    signature // Assuming this is a File object (e.g., PNG)
) => {
    if (
        employeeNo === "" ||
        firstName === "" ||
        lastName === "" ||
        password === "" ||
        mobileNo === "" ||
        email === ""
    ) {
        return "1111";
    }

    // Use FormData for file upload
    const formData = new FormData();
    formData.append("trx_accessLevel", accessLevel);
    formData.append("trx_regionalOfficeID", regionalOfficeID || null);
    formData.append("trx_districtOfficeID", districtOfficeID || null);
    formData.append("trx_districtOffice", districtOffice || null);
    formData.append("trx_regionalOffice", regionalOffice || null);
    formData.append("trx_positionName", positionName);
    formData.append("trx_employeeNo", employeeNo);
    formData.append("trx_firstName", firstName);
    formData.append("trx_middleName", middleName);
    formData.append("trx_lastName", lastName);
    formData.append("trx_password", password);
    formData.append("trx_mobileNo", mobileNo);
    formData.append("trx_email", email);
    formData.append("trx_status", status);

    // Append the signature as a file
    formData.append("trx_signature", signature); // Assuming `signature` is a PNG File object

    try {
        let res = await fetch("https://api.nha.gov.ph/ep/trx_addusers", {
            mode: "cors",
            method: "POST",
            credentials: "include",
            body: formData, // Use FormData instead of JSON.stringify
        });

        if (res.status === 200) {
            return "0000";
        } else {
            console.error("Error response:", await res.json());
            return "1111";
        }
    } catch (err) {
        console.error("Fetch error:", err);
    }

    return "0000";
};

export default fxaddUsers;
