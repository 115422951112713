import { PDFDocument } from "pdf-lib";
import axios from "axios";
import { toast } from "react-toastify";

// Function to merge multiple PDFs
const mergePDFs = async (files) => {
    const mergedPdf = await PDFDocument.create();

    for (const file of files) {
        const arrayBuffer = await file.arrayBuffer();
        const pdfToMerge = await PDFDocument.load(arrayBuffer);

        const copiedPages = await mergedPdf.copyPages(
            pdfToMerge,
            pdfToMerge.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
    return blob; // Return the Blob for uploading
};

const UploadPdf = async (selectedFiles, requestNumber) => {
    if (!selectedFiles.length || !requestNumber) {
        toast.error("Please select files and provide a request number.");
        return;
    }

    try {
        // Merge the selected PDFs into one, or handle single file
        const mergedPdfBlob = await mergePDFs(selectedFiles); // Merge multiple files

        const formData = new FormData();
        formData.append("file", mergedPdfBlob, "merged.pdf"); // Name the merged PDF file
        formData.append("requestNumber", requestNumber);

        await axios.post("https://api.nha.gov.ph/ep/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true, // Include cookies if needed
        });

        toast.success("Merged PDF uploaded successfully!");
    } catch (error) {
        console.error(error);
        toast.error("File upload failed. Please try again later.");
    }
};

export default UploadPdf;
