import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";
// mui
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const EPDMS = () => {
    // mui select - input
    const selectLabelStyle = {
        fontWeight: "normal",
    };
    const labelStyle = {
        fontWeight: "normal",
    };

    // mui select
    const [requestno, setrequestno] = useState("");
    const handlerequestno = (event) => {
        setrequestno(event.target.value);
    };

    const [formData, setFormData] = useState();
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [filterText, setFilterText] = useState("");
    const [filterColumn, setFilterColumn] = useState("requestNumber");
    const [filteredSortColumn, setFilteredSortColumn] = useState(null);
    const [filteredSortOrder, setFilteredSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(1000);
    const totalPages = Math.ceil(beneficiaries.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, beneficiaries.length);

    useEffect(() => {
        const fetchBeneficiaries = async () => {
            try {
                const response = await axios.get(
                    "https://api.nha.gov.ph/ep/trx_ep_dms"
                );
                setBeneficiaries(response.data.beneficiaries);
            } catch (error) {
                console.error("Error fetching beneficiaries:", error);
            }
        };

        fetchBeneficiaries();
    }, []);
    const filteredBeneficiaries = beneficiaries.filter((beneficiary) => {
        // Check if the filterColumn exists in the beneficiary object
        const filterColumnValue = beneficiary[filterColumn];

        // Check if the filterColumnValue is not undefined and not null before calling toLowerCase()
        const filterColumnValueLower = filterColumnValue
            ? String(filterColumnValue).toLowerCase() // Ensure filterColumnValue is converted to a string before calling toLowerCase()
            : "";

        // Check if Spouse exists in the beneficiary object
        const spouse = beneficiary.Spouse;

        // Check if pointOfOrigin exists in the beneficiary object
        const pointOfOrigin = beneficiary.PointOfOrigin;
        const resettlement = beneficiary.Resettlement;

        if (spouse || pointOfOrigin || resettlement) {
            // Check if spouse or pointOfOrigin and their properties exist before accessing them
            const spouseValue = spouse ? spouse[filterColumn] : "";
            const pointOfOriginValue = pointOfOrigin
                ? pointOfOrigin[filterColumn]
                : "";
            const resettlementValue = resettlement
                ? resettlement[filterColumn]
                : "";

            const spouseValueLower = spouseValue
                ? spouseValue.toLowerCase()
                : "";
            const pointOfOriginLower = pointOfOriginValue
                ? pointOfOriginValue.toLowerCase()
                : "";
            const resettlementLower = resettlementValue
                ? resettlementValue.toLowerCase()
                : "";

            // Apply filter logic for spouse and pointOfOrigin
            return (
                filterColumnValueLower.includes(filterText.toLowerCase()) ||
                spouseValueLower.includes(filterText.toLowerCase()) ||
                pointOfOriginLower.includes(filterText.toLowerCase()) ||
                resettlementLower.includes(filterText.toLowerCase())
            );
        } else {
            // If there is no spouse or pointOfOrigin, only filter based on the primary beneficiary's information
            return filterColumnValueLower.includes(filterText.toLowerCase());
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const openPrintModal = () => {
        setIsPrintModalOpen(true);

        // Call any other necessary functions or set any state related to opening the modal here
    };

    // Function to close the print modal
    const closePrintModal = () => {
        setIsPrintModalOpen(false);
        // Reset any state related to the modal here
    };
    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const handleFilteredSort = (column) => {
        if (column === filteredSortColumn) {
            setFilteredSortOrder(filteredSortOrder === "asc" ? "desc" : "asc");
        } else {
            setFilteredSortColumn(column);
            setFilteredSortOrder("asc");
        }
    };

    // Sort the filtered beneficiaries based on the selected column and order
    let sortedFilteredBeneficiaries = filteredBeneficiaries.slice(); // Create a shallow copy
    let sortedFilteredBeneficiariesCount = sortedFilteredBeneficiaries.length; // Create a shallow copy
    if (filteredSortColumn) {
        sortedFilteredBeneficiaries = sortedFilteredBeneficiaries.sort(
            (a, b) => {
                const aValue = a[filteredSortColumn];
                const bValue = b[filteredSortColumn];
                if (aValue < bValue)
                    return filteredSortOrder === "asc" ? -1 : 1;
                if (aValue > bValue)
                    return filteredSortOrder === "asc" ? 1 : -1;
                return 0;
            }
        );
    }

    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
    };

    const handleFilterColumnChange = (e) => {
        setFilterColumn(e.target.value);
    };
    // document
    //     .querySelector(".non-scroll")
    //     .addEventListener("scroll", function (event) {
    //         event.preventDefault();
    //     });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBeneficiaries = sortedFilteredBeneficiaries.slice(
        indexOfFirstItem,
        indexOfLastItem
    );
    const handlePageChange = (pageNumber) => {
        const nextPage = Math.max(1, Math.min(pageNumber, totalPages));
        setCurrentPage(nextPage);
    };
    return (
        <div>
            <Header />
            <Sidebar />

            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Entry Pass DMS</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="pl-2 pr-2">
                    <div className="container-fluid">
                        <div className="row mt-3 mb-3">
                            <div
                                className="col-sm-2"
                                style={{ borderRight: "2px solid #ccc" }}
                            >
                                <TextField
                                    placeholder="Search here"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={filterText}
                                    onChange={handleFilterChange}
                                    size="small"
                                    InputLabelProps={{ style: labelStyle }}
                                    type="text"
                                />
                            </div>
                            <div className="col-sm-2 pt-2 pl-3">
                                <p style={{ margin: "auto" }}>
                                    Total Count:{" "}
                                    <b>{currentBeneficiaries.length}</b>
                                </p>
                            </div>
                            <div className="col-sm-8">
                                <FormControl
                                    size="small"
                                    className="float-right w-25"
                                >
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        style={selectLabelStyle}
                                    >
                                        Filter by
                                    </InputLabel>
                                    <Select
                                        labelId=""
                                        id=""
                                        value={filterColumn}
                                        label="Filter"
                                        onChange={handleFilterColumnChange}
                                    >
                                        <MenuItem
                                            value="requestNumber"
                                            className="text-sm"
                                        >
                                            Request Number
                                        </MenuItem>
                                        <MenuItem
                                            value="controlNumber"
                                            className="text-sm"
                                        >
                                            Control Number
                                        </MenuItem>
                                        <MenuItem
                                            value="lastName"
                                            className="text-sm"
                                        >
                                            Household Head Last Name
                                        </MenuItem>
                                        <MenuItem
                                            value="firstName"
                                            className="text-sm"
                                        >
                                            Household Head First Name
                                        </MenuItem>
                                        <MenuItem
                                            value="middleName"
                                            className="text-sm"
                                        >
                                            Household Head Middle Name
                                        </MenuItem>
                                        <MenuItem
                                            value="sex"
                                            className="text-sm"
                                        >
                                            Household Head Sex
                                        </MenuItem>
                                        <MenuItem
                                            value="spouseLastName"
                                            className="text-sm"
                                        >
                                            Spouse Last Name
                                        </MenuItem>
                                        <MenuItem
                                            value="spouseFirstName"
                                            className="text-sm"
                                        >
                                            Spouse First Name
                                        </MenuItem>
                                        <MenuItem
                                            value="spouseMiddleName"
                                            className="text-sm"
                                        >
                                            Spouse Middle Name
                                        </MenuItem>
                                        <MenuItem
                                            value="spouseSex"
                                            className="text-sm"
                                        >
                                            Spouse Sex
                                        </MenuItem>
                                        <MenuItem
                                            value="civilStatus"
                                            className="text-sm"
                                        >
                                            Civil Status
                                        </MenuItem>
                                        <MenuItem
                                            value="areaClassification"
                                            className="text-sm"
                                        >
                                            Area/Program Classification
                                        </MenuItem>
                                        <MenuItem
                                            value="projectName"
                                            className="text-sm"
                                        >
                                            Project Name
                                        </MenuItem>
                                        <MenuItem
                                            value="resettlementSiteName"
                                            className="text-sm"
                                        >
                                            Resettlement Site Project Name
                                        </MenuItem>
                                        <MenuItem
                                            value="chargeability"
                                            className="text-sm"
                                        >
                                            Chargeability
                                        </MenuItem>
                                        <MenuItem
                                            value="epPrintStatus"
                                            className="text-sm"
                                        >
                                            Status of Utilization
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div
                            className="card elevation-2"
                            style={{
                                borderTop: "4px solid #292726",
                                borderRadius: "15px",
                                margin: "0",
                            }}
                        >
                            <div
                                className="card-body table-responsive p-0"
                                style={{
                                    height: "700px",
                                    borderRadius: "17px",
                                }}
                            >
                                <table className="table table-head-fixed bg-white table-hover table-bordered text-wrap p-0">
                                    <thead
                                        style={{
                                            position: "sticky",
                                            top: "0",
                                            backgroundColor: "f4f4f4",
                                            zIndex: "10",
                                        }}
                                    >
                                        <tr>
                                            <th
                                                colspan="24"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                EP PREPARED
                                            </th>
                                            <th
                                                colspan="5"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                EP PREPARED
                                            </th>
                                        </tr>

                                        <tr className="text-center text-sm border-1">
                                            <th
                                                rowspan="2"
                                                className="text-middle"
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "sequenceNumber"
                                                    )
                                                }
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                SEQ NO.
                                            </th>
                                            <th
                                                rowspan="2"
                                                className="text-middle"
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "requestNumber"
                                                    )
                                                }
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REQ NO.
                                            </th>
                                            <th
                                                rowspan="2"
                                                className="text-middle"
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "controlNumber"
                                                    )
                                                }
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                EP CTRL NO.
                                            </th>
                                            <th
                                                colspan="4"
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                HOUSEHOLD HEAD
                                            </th>
                                            <th
                                                colspan="4"
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                SPOUSE
                                            </th>
                                            <th
                                                rowspan="2"
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "civilStatus"
                                                    )
                                                }
                                            >
                                                MARITAL STATUS
                                            </th>
                                            <th
                                                rowspan="2"
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort("")
                                                }
                                            >
                                                TYPE OF RELOCATION
                                            </th>
                                            <th
                                                colspan="5"
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                PLACE OF ORIGIN
                                            </th>
                                            <th
                                                colspan="3"
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                RESETTLEMENT PROJECT
                                            </th>
                                            <th
                                                colspan="3"
                                                className="text-middle"
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "relocationDate"
                                                    )
                                                }
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DATE PREPARED
                                            </th>
                                            <th
                                                colspan="1"
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                STATUS OF UTILIZATION
                                            </th>
                                            <th
                                                colspan="3"
                                                className="text-middle"
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "epReleaseDate"
                                                    )
                                                }
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DATE RELOCATED / ISSUED EP
                                            </th>
                                            <th
                                                rowspan="2"
                                                className="text-middle"
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "epRemarks"
                                                    )
                                                }
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REMARKS
                                            </th>
                                            {/* <th colspan="3" className='text-middle' style={{border: '1px solid #ccc', backgroundColor: '#f4f4f4'}}>RESETTLEMENT PROJECT</th> */}
                                        </tr>
                                        <tr className="text-center text-sm">
                                            <th
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "lastName"
                                                    )
                                                }
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                LAST NAME
                                            </th>
                                            <th
                                                className="text-middle"
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "firstName"
                                                    )
                                                }
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                FIRST NAME
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "middleName"
                                                    )
                                                }
                                            >
                                                MIDDLE NAME
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort("sex")
                                                }
                                            >
                                                SEX
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "spouseLastName"
                                                    )
                                                }
                                            >
                                                LAST NAME
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "spouseFirstName"
                                                    )
                                                }
                                            >
                                                FIRST NAME
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "spouseMiddleName"
                                                    )
                                                }
                                            >
                                                MIDDLE NAME
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "spouseSex"
                                                    )
                                                }
                                            >
                                                SEX
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "areaClassification"
                                                    )
                                                }
                                            >
                                                AREA/PROGRAM CLASSIFICATION
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "projectName"
                                                    )
                                                }
                                            >
                                                PROJECT NAME (if applicable)
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "cityAddr"
                                                    )
                                                }
                                            >
                                                LGU
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "barangayAddr"
                                                    )
                                                }
                                            >
                                                BRGY.
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                SPECIFIC AREA
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "resettlementSiteName"
                                                    )
                                                }
                                            >
                                                PROJECT NAME
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort("city")
                                                }
                                            >
                                                LGU
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "chargeability"
                                                    )
                                                }
                                            >
                                                CHARGEABILITY
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                MM
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DD
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                YYYY
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                                onClick={() =>
                                                    handleFilteredSort(
                                                        "epPrintStatus"
                                                    )
                                                }
                                            >
                                                (Issued, Cancelled, Deferred)
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                MM
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DD
                                            </th>
                                            <th
                                                className="text-middle"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                YYYY
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentBeneficiaries.map(
                                            (beneficiary, index) => {
                                                const beneficiaryIndex =
                                                    startIndex + index + 1;
                                                const [mm, dd, yyyy] =
                                                    beneficiary.relocationDate
                                                        ? beneficiary.relocationDate.split(
                                                              "-"
                                                          )
                                                        : ["", "", ""];
                                                const [mm1, dd1, yyyy1] =
                                                    beneficiary.epReleaseDate
                                                        ? beneficiary.epReleaseDate.split(
                                                              "-"
                                                          )
                                                        : ["", "", ""];
                                                return (
                                                    <tr key={beneficiaryIndex}>
                                                        {" "}
                                                        <td className="text-center">
                                                            {beneficiaryIndex}
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                beneficiary.requestNumber
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {" "}
                                                            {
                                                                beneficiary.controlNumber
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary.lastName
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary.firstName
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary.middleName
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {beneficiary.sex}
                                                        </td>
                                                        <td>
                                                            {beneficiary.Spouse
                                                                ? beneficiary
                                                                      .Spouse
                                                                      .spouseLastName
                                                                : "N/A"}
                                                        </td>
                                                        <td>
                                                            {beneficiary.Spouse
                                                                ? beneficiary
                                                                      .Spouse
                                                                      .spouseFirstName
                                                                : "N/A"}
                                                        </td>
                                                        <td>
                                                            {beneficiary.Spouse
                                                                ? beneficiary
                                                                      .Spouse
                                                                      .spouseMiddleName
                                                                : "N/A"}
                                                        </td>
                                                        <td className="text-center">
                                                            {beneficiary.Spouse
                                                                ? beneficiary
                                                                      .Spouse
                                                                      .spouseSex
                                                                : "N/A"}
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                beneficiary.civilStatus
                                                            }
                                                        </td>
                                                        <td>
                                                            {beneficiary
                                                                ? beneficiary.typeOfRelocation
                                                                : "N/A"}
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .PointOfOrigin
                                                                    .areaClassification
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .PointOfOrigin
                                                                    .projectName
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .PointOfOrigin
                                                                    .cityAddr
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .PointOfOrigin
                                                                    .barangayAddr
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .PointOfOrigin
                                                                    .specificArea
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .Resettlement
                                                                    .resettlementSiteName
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .Resettlement
                                                                    .city
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                beneficiary
                                                                    .Resettlement
                                                                    .chargeability
                                                            }
                                                        </td>
                                                        <td>{dd}</td>
                                                        <td>{yyyy}</td>
                                                        <td>{mm}</td>
                                                        <td>
                                                            {
                                                                beneficiary.epPrintStatus
                                                            }
                                                        </td>
                                                        <td> {dd1}</td>
                                                        <td> {yyyy1}</td>
                                                        <td> {mm1}</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                beneficiary.epRemarks
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                className="card-footer"
                                style={{
                                    borderBottomLeftRadius: "15px",
                                    borderBottomRightRadius: "15px",
                                }}
                            >
                                {/* <div className="row cardFooterCenter">
                                    <div className="col-sm-6">
                                        <label>1</label>{" "}
                                        <span className="text-muted">of</span>{" "}
                                        <label>3</label> &nbsp;
                                        <IconButton>
                                            <ArrowBackIosIcon className="text-md pl-1" />
                                        </IconButton>
                                        <IconButton>
                                            <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                        </IconButton>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EPDMS;
