import React, { useState } from "react";
import "./Modal.css";
import { Mqhrr } from "./Mqhrr/Mqhrr";
import { useNavigate } from "react-router-dom";

const Modal = ({
    isOpen,
    onClose,
    children,
    batchNumber,
    formData,
    selectedIds,
    inRequest,
    print,
    isBatchPage, // Prop to check if it's the Batch.js page
}) => {
    const [isClosing, setIsClosing] = useState(false);

    const handleModalClick = (event) => {
        event.stopPropagation();
    };

    const handleOverlayClick = (event) => {
        if (
            event.target.classList.contains("modal-overlay") ||
            event.target.getAttribute("id") === "closeButton"
        ) {
            onClose();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handlePrint = () => {
        Mqhrr({ batchNumber, formData });
        onClose(); // Close the modal after printing
    };

    return isOpen ? (
        <div className={`modal ${isClosing ? "closing" : ""}`}>
            <div className="modal-overlay" onClick={handleOverlayClick}>
                <div className="modal-content" onClick={handleModalClick}>
                    {children}
                    <div className="modal-btn-card">
                        {isBatchPage && ( // Conditionally render Cancel button
                            <div style={{ width: '48%' }}>
                                <button className="modal-btn-cancel" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        )}
                        <div style={{ width: '48%' }}>
                            {!print ? (
                                <button
                                    onClick={handlePrint}
                                    className="modal-btn-print"
                                    disabled={
                                        !batchNumber ||
                                        Object.values(formData).some((value) => !value)
                                    }
                                >
                                    Print
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Modal;
