import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function OtpButton({ onOtpVerified }) {
    const [otpSent, setOtpSent] = useState(false); // Tracks if OTP has been sent
    const [timer, setTimer] = useState(0); // Timer for resend
    const [isLoading, setIsLoading] = useState(false); // For loading spinner
    const [otp, setOtp] = useState(""); // State to hold user-input OTP
    const [isVerified, setIsVerified] = useState(false); // Track if OTP is verified

    // Function to handle OTP send
    const handleSendOtp = async () => {
        setIsLoading(true);
        const userId = Cookies.get("userId"); // Get userId from cookies

        try {
            // Call the API to send OTP with cookies included
            await axios.post(
                "https://api.nha.gov.ph/ep/trx_create_otp_request",
                { userId },
                { withCredentials: true } // Include cookies in the request
            );
            setOtpSent(true);
            setTimer(30); // Set a 30-second cooldown for resend
            toast.success("OTP sent successfully!"); // Show success toast
            onOtpVerified(true);
        } catch (error) {
            console.error(
                "Error sending OTP:",
                error.response ? error.response.data.error : error.message
            );
            onOtpVerified(false);
            toast.error("Failed to send OTP. Please try again."); // Show error toast
        } finally {
            setIsLoading(false);
        }
    };

    // Countdown timer for resend
    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval); // Cleanup interval
        }
    }, [timer]);

    // Function to handle OTP resend
    const handleResendOtp = async () => {
        const userId = Cookies.get("userId"); // Get userId from cookies

        try {
            setIsLoading(true);
            // Call the API to resend OTP with cookies included
            await axios.post(
                "https://api.nha.gov.ph/ep/trx_resend_otp_request",
                { userId },
                { withCredentials: true } // Include cookies in the request
            );
            setTimer(30); // Reset the timer for resend
            toast.success("OTP resent successfully!"); // Show success toast
        } catch (error) {
            console.error(
                "Error resending OTP:",
                error.response ? error.response.data.error : error.message
            );
            toast.error("Failed to resend OTP. Please try again."); // Show error toast
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle OTP verification
    const handleVerifyOtp = async () => {
        setIsLoading(true);
        const userId = Cookies.get("userId"); // Get userId from cookies

        try {
            // Call the API to verify OTP with cookies included
            const response = await axios.post(
                "https://api.nha.gov.ph/ep/trx_verify_request",
                {
                    userId,
                    otp,
                },
                { withCredentials: true } // Include cookies in the request
            );
            setIsVerified(true); // Set verification status
            toast.success("OTP verified successfully!"); // Show success toast
        } catch (error) {
            console.error(
                "Error verifying OTP:",
                error.response ? error.response.data.error : error.message
            );
            toast.error("Failed to verify OTP. Please try again."); // Show error toast
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <button
                className="btn btn-primary"
                onClick={otpSent && timer > 0 ? handleResendOtp : handleSendOtp}
                disabled={isLoading || (otpSent && timer > 0)} // Disable button while sending or during cooldown
            >
                {isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                ) : otpSent ? (
                    timer > 0 ? (
                        `Resend OTP in ${timer}s`
                    ) : (
                        "Resend OTP"
                    )
                ) : (
                    "Send OTP"
                )}
            </button>
            {/* Input for OTP */}
            {otpSent && !isVerified && (
                <div className="mt-3">
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        className="form-control"
                    />
                    <button
                        className="btn btn-success mt-2"
                        onClick={handleVerifyOtp}
                        disabled={isLoading || !otp} // Disable button if loading or OTP is empty
                    >
                        {isLoading ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            "Verify OTP"
                        )}
                    </button>
                </div>
            )}
            <ToastContainer /> {/* Container for toast notifications */}
        </div>
    );
}

export default OtpButton;
