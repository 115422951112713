import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

const SummaryReport = () => {
    const [requests, setRequests] = useState(null);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await axios.get(
                    `https://api.nha.gov.ph/ep/fetch_summary_requests`,
                    {
                        withCredentials: true, // Include cookies with the request
                    }
                );
                setRequests(response.data.requests);
            } catch (error) {
                console.error("Error fetching requests:", error);
            }
        };

        fetchRequests();
    }, []);

    return (
        <div>
            <Header />
            <Sidebar />

            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Summary Report</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="pl-2 pr-2">
                    <div className="container-fluid">
                        <div
                            className="card elevation-2"
                            style={{
                                borderTop: "4px solid #292726",
                                borderRadius: "15px",
                            }}
                        >
                            <div className="card-header pb-1">
                                <p className="mb-0">
                                    National Housing Authority
                                </p>
                                <p className="mb-0">
                                    Resettlement & Development Services
                                    Department
                                </p>
                                <p className="mb-0">
                                    Relocation & Resettlement Monitoring
                                    Division
                                </p>
                                <h5 className="font-weight-bold pt-2">
                                    SUMMARY REPORT ON ENTRY PASS REQUISITION
                                </h5>
                            </div>

                            <div
                                className="card-body table-responsive p-0"
                                style={{ height: 600 }}
                            >
                                <table className="table table-head-fixed bg-white table-hover table-bordered text-wrap">
                                    <thead
                                        style={{
                                            borderBottom: "2px solid #ccc",
                                        }}
                                    >
                                        <tr
                                            className="text-sm"
                                            style={{
                                                position: "sticky",
                                                top: 0, // Adjust the value if needed
                                                zIndex: 1,
                                            }}
                                        >
                                            <th
                                                rowSpan="3"
                                                className="text-middle text-center"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NO.
                                            </th>
                                            <th
                                                colSpan="6"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REQUISITION
                                            </th>
                                            <th
                                                colSpan="4"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DATE & TIME OF RECEIPT
                                            </th>
                                            <th
                                                colSpan="5"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DATE & TIME OF RECEIPT
                                            </th>
                                            <th
                                                rowSpan="3"
                                                className="text-middle text-center"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REMARKS
                                            </th>
                                        </tr>

                                        <tr
                                            className="text-center text-sm"
                                            style={{
                                                position: "sticky",
                                                top: "36px", // Height of the first row, adjust accordingly
                                                zIndex: 1,
                                            }}
                                        >
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DISTRICT OFFICE
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REGIONAL OFFICE
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REQUEST NO.
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NAME OF REGIONAL EVALUATOR
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NAME OF RDSD EVALUATOR
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                PLACE OF ORIGIN/PROJECT NAME
                                            </th>
                                            <th
                                                colSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REGIONAL OFFICE
                                            </th>
                                            <th
                                                colSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                RDSD
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NO. OF EP REQUEST
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                ISSUED
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DEFERRED
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                CANCELLED
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                SPOILED
                                            </th>
                                        </tr>

                                        <tr
                                            className="text-center text-sm"
                                            style={{
                                                position: "sticky",
                                                top: "72px", // Height of the first and second rows combined, adjust accordingly
                                                zIndex: 1,
                                            }}
                                        >
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                IN
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                OUT
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                IN
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                OUT
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {requests
                                            ? requests.map((request, index) => {
                                                  const epReq =
                                                      request.EntryPassRequisition; // Access EntryPassRequisition for each request
                                                  return (
                                                      <tr key={index + 1}>
                                                          <td className="text-middle">
                                                              {index + 1}
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.districtOffice
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.regionalOffice
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.requestNumber
                                                              }
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.districtEvaluator
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.regionalEvaluator
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.projectName
                                                              }
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.regionalIn
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.regionalOut
                                                                  : "="}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdIn
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdOut
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfEPRequest
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfIssued
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfDeferred
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfCancelled
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfSpoiled
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td></td>
                                                      </tr>
                                                  );
                                              })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryReport;
